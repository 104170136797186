import * as React from 'react';
import {
  Guarantees,
  Examples,
  Benefits,
  Reviews,
  BottomForm,
} from 'components';
import { Seo } from 'components/seo';
import { Layout } from 'ui/layouts';

const IndexPage: React.FC = () => {
  return (
    <>
      <Layout>
        <Seo title="Studybaychat Brazil" description="Studybaychat Brazil"/>
        <Guarantees />
        <Examples />
        <Benefits />
        <Reviews />
        <BottomForm />
      </Layout>
    </>
  );
};

export default IndexPage;
